import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { ShoppingService } from 'src/app/services/shopping.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-select-type-pay-modal',
  templateUrl: './select-type-pay-modal.component.html',
  styleUrls: ['./select-type-pay-modal.component.scss'],
})
export class SelectTypePayModalComponent implements OnInit {
  viewCart: any = [];

  selectPay = true;

  goPayBoolean = false;
  loading = false;

  totalCart!: number;
  totalService!: number;
  payToServiceByTicket: number = 500;

  user: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SelectTypePayModalComponent>,
    public checkoutService: CheckoutService,
    public shopping: ShoppingService,
    private authService: AuthService,

    public router: Router
  ) {}

  async ngOnInit() {
    this.viewCart = this.shopping.getShoppingCart();
    console.log(this.viewCart);
    this.calculateTotalCart();

    await this.authService.getUserFirebase().subscribe((x) => {
      this.user = x;
      console.log(this.user);
    });
  }
  closeDialog(action: any) {
    this.dialogRef.close(action);
  }

  async goPay() {
    this.goPayBoolean = true;
    this.loading = true;
    if (this.router.url.includes('/events')) {
      (await this.checkoutService.checkoutAccess(this.viewCart)).subscribe(
        (data) => {
          console.log(data);

          if (data.result) {
            window.location.href = data.result.init_point;
            setTimeout(() => {}, 2000);
          } else {
            if (data.code === 404) {
              let error = 'Tickets Agotados : </br>';
              data.error.forEach((element: any) => {
                error +=
                  '⦿ ' +
                  element.name +
                  '(Solo quedan ' +
                  (element.amount - element.sold) +
                  ' Tickets disponibles.)' +
                  '</br>';
              });
              Swal.fire({
                position: 'center',
                icon: 'info',
                title: data.message,
                footer: error,
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: 'Cerrar',
                // timer: 2500,
              });
            }

            this.goPayBoolean = false;
            this.loading = false;
          }
        }
      );
    } else {
      (await this.checkoutService.checkoutBar(this.viewCart)).subscribe(
        (data) => {
          if (data.result) {
            window.location.href = data.result.init_point;
            setTimeout(() => {}, 2000);
          } else {
            alert('checkoutBar ' + data.message);
          }
        }
      );
    }
  }

  calculateTotalCart() {
    this.totalCart = this.viewCart.reduce(
      (acc: any, item: any) => acc + item.quantity * item.price,
      0
    );

    this.totalService = this.viewCart.reduce(
      (acc: any, item: any) => acc + item.quantity * this.payToServiceByTicket,
      0
    );
  }
}
