<div class="example-container">

    <app-toolbar-home-admin *ngIf="
    router.url != '/admin/login' " [sidenavMenuAdmin]="sidenavMenuAdmin">
    </app-toolbar-home-admin>


    <mat-sidenav-container>

        <mat-sidenav #sidenavMenuAdmin (closed)="navclose()" (opened)="navOpen()" position="start"
            class="footer d-flex " style="margin-top: 3.5rem !important; ">
            <div class="container  mt-3">

                <div class="col-12 mt-3 mb-3 container">


                    <mat-icon style="float: right;" (click)="closeSideNav(sidenavMenuAdmin)">close
                    </mat-icon>



                </div>

                <div class="row d-flex justify-content-center align-items-center col-12" style="padding: 16px;">
                    <div class="col-3 ">
                        <img class="card-img-top rounded" [src]="this.authService.getUser().photoURL">
                    </div>
                    <div class="col-9">
                        <p class="m-0">Bienvenido</p>
                        <p class=" m-0"> {{this.authService.getUser().displayName}}</p>
                    </div>

                </div>


                <mat-selection-list [multiple]="false">
                    <div mat-subheader>Cuenta</div>

                    <mat-list-option (click)="goPage('/dashboard',sidenavMenuAdmin) "
                        [ngClass]="{'menuSelect':router.url.includes('/admin/dashboard')}">
                        <mat-icon mat-list-icon>dashboard</mat-icon>
                        <div mat-line>Inicio</div>
                    </mat-list-option>

                    <!-- <mat-list-option (click)="goPage('/stores',sidenavMenuAdmin) "
                        [ngStyle]="{'background-color':router.url == '/admin/stores'? '#c2185b63' : 'unset' }">
                        <mat-icon mat-list-icon>storefront</mat-icon>
                        <div mat-line>Locales</div>
                    </mat-list-option> -->

                    <mat-list-option (click)="goPage('/menus',sidenavMenuAdmin) "
                        [ngClass]="{'menuSelect':router.url.includes('/admin/menus')}">

                        <mat-icon mat-list-icon>menu_book</mat-icon>
                        <div mat-line>Cartas</div>
                    </mat-list-option>

                    <mat-list-option [matMenuTriggerFor]="menu"
                        [ngClass]="{'menuSelect':router.url.includes('/admin/events')}">

                        <mat-icon mat-list-icon>celebration</mat-icon>


                        <div class=" align-items-center">Eventos

                            <mat-icon style="float: right;">expand_more</mat-icon>


                        </div>



                        <mat-menu #menu="matMenu">
                            <button [ngClass]="router.url.includes('/admin/events-future')?'menuSelect':'menuNoSelect' "
                                mat-menu-item (click)="goPage('/events-future',sidenavMenuAdmin) ">
                                <mat-icon>event</mat-icon>
                                <span>Eventos Futuros</span>
                            </button>
                            <button [ngClass]="router.url.includes('/admin/events-past')?'menuSelect':'menuNoSelect' "
                                mat-menu-item (click)="goPage('/events-past',sidenavMenuAdmin) ">
                                <mat-icon>event_available</mat-icon>
                                <span>Eventos Pasados</span>
                            </button>

                            <button [ngClass]="router.url.includes('/admin/events-delete')?'menuSelect':'menuNoSelect' "
                                mat-menu-item (click)="goPage('/events-delete',sidenavMenuAdmin) ">
                                <mat-icon>delete</mat-icon>
                                <span>Eventos Eliminados</span>
                            </button>


                        </mat-menu>

                    </mat-list-option>

                    <!-- <mat-list-option (click)="goPage('/stores',sidenavMenuAdmin) "
                        [ngStyle]="{'background-color':  router.url.includes('/admin/stores') ? '#c2185b63' : 'unset' }">

                        <mat-icon mat-list-icon>store</mat-icon>
                        <div mat-line>Locales</div>
                    </mat-list-option> -->


                    <mat-divider class="mt-2"></mat-divider>
                    <div mat-subheader>Finanzas</div>
                    <!-- <mat-list-option (click)="goPage('/sales',sidenavMenuAdmin) "
                        [ngClass]="{'menuSelect':router.url.includes('/admin/sales')}">
                        <mat-icon mat-list-icon>sell</mat-icon>
                        <div mat-line>Ventas</div>
                    </mat-list-option> -->

                    <mat-list-option (click)="goPage('/clients',sidenavMenuAdmin) "
                        [ngClass]="{'menuSelect':router.url.includes('/admin/clients')}">
                        <mat-icon mat-list-icon>group</mat-icon>
                        <div mat-line>Clientes</div>
                    </mat-list-option>

                    <mat-list-option (click)="goPage('/banks',sidenavMenuAdmin) "
                        [ngClass]="{'menuSelect':router.url.includes('/admin/banks')}">
                        <mat-icon mat-list-icon>savings</mat-icon>
                        <div mat-line>Cuenta Bancaria</div>
                    </mat-list-option>



                    <mat-divider class="mt-2"></mat-divider>
                    <div mat-subheader>Configuración</div>
                    <mat-list-option (click)="goPage('/staff-qr',sidenavMenuAdmin) "
                        [ngClass]="{'menuSelect':router.url.includes('/admin/staff-qr')}">
                        <mat-icon mat-list-icon>barcode_reader</mat-icon>
                        <div mat-line>Trabajadores</div>
                    </mat-list-option>
                    <!-- <mat-list-option (click)="goPage('/staff-qr/real-time',sidenavMenuAdmin) "
                        [ngClass]="{'menuSelect':router.url.includes('/admin/staff-qr/real-time')}">
                        <mat-icon mat-list-icon>qr_code_scanner</mat-icon>
                        <div mat-line>Real Time Workers</div>
                    </mat-list-option> -->
                    <mat-list-option (click)="logout(); sidenavMenuAdmin.toggle()">
                        <mat-icon mat-list-icon>logout</mat-icon>
                        <div mat-line>Salir</div>
                    </mat-list-option>


                    <mat-divider class="mt-2"></mat-divider>
                    <div mat-subheader>Otras apps</div>


                    <mat-list-option (click)="goOtherPage('/workers',sidenavMenuAdmin) "
                        [ngClass]="{'menuSelect':router.url.includes('/workers')}">
                        <mat-icon mat-list-icon>engineering</mat-icon>
                        <div mat-line>Workers</div>
                    </mat-list-option>

                    <mat-list-option (click)="goOtherPage('/',sidenavMenuAdmin) ">
                        <mat-icon mat-list-icon>home</mat-icon>
                        <div mat-line>App </div>
                    </mat-list-option>

                </mat-selection-list>
            </div>


        </mat-sidenav>


        <mat-sidenav-content [ngStyle]="{'margin-top':router.url != '/admin/login' ? '4rem' : 'unset' }">
            <h1 *ngIf="router.url != '/admin/login'" class=" uppercase text-center tittle-navbar-admin mt-2"></h1>

            <router-outlet class="min-height-100"></router-outlet>


            <app-home-footer></app-home-footer>

        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

<div *ngIf="viewBack" class=" backButtom">
    <button (click)="clickBack()" color="primary" matBadgePosition="after" matBadgeColor="warn" mat-mini-fab
        aria-label="">
        <mat-icon>arrow_back</mat-icon>


    </button>
</div>