import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ShoppingService } from 'src/app/services/shopping.service';
import { NavigationEnd, Router } from '@angular/router';
import { CheckoutService } from 'src/app/services/checkout.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-workers-layout',
  templateUrl: './workers-layout.component.html',
  styleUrls: ['./workers-layout.component.scss'],
})
export class WorkersLayoutComponent implements OnInit {
  user: any = {};

  viewBack = false;
  isBack = false;
  private history: string[] = [];

  constructor(
    public authService: AuthService,
    public shopping: ShoppingService,
    public checkoutService: CheckoutService,
    public spinnerService: SpinnerService,
    public router: Router,
    public usersService: UsersService
  ) {}

  logout() {
    this.authService.logout();
  }

  async ngOnInit(): Promise<void> {
    this.spinnerService.showSpinner();

    setTimeout(() => {
      this.spinnerService.hideSpinner();
    }, 2000);

    if (!this.authService.getUser().isWorker) {
      this.authService.logout();
    }

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (!this.isBack) {
          this.viewBack = true;
          this.history.push(val.urlAfterRedirects);
        }
        this.isBack = false;
      }

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    });
  }

  goPage(page: string, sidenavMenuAdmin: any) {
    sidenavMenuAdmin.toggle();
    this.router.navigate(['/workers' + page]);
  }

  goOtherPage(page: string, sidenavMenuAdmin: any) {
    sidenavMenuAdmin.toggle();
    this.router.navigate(['/' + page]);
  }

  clickBack() {
    this.isBack = true;
    if (this.history.length > 0) {
      history.back();

      if (this.history.length == 1) {
        this.viewBack = false;
      }
      this.history.pop();
    }
  }

  navOpen() {
    let element = document.querySelector('.backButtom ') as HTMLElement;
    if (element) {
      element.style.display = 'none';
    }
  }
  navclose() {
    let element = document.querySelector('.backButtom ') as HTMLElement;
    if (element) {
      element.style.display = 'block';
    }
  }

  closeSideNav(sidenav: any) {
    sidenav.toggle();
  }
}
