import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class ShoppingService {
  constructor(private storageService: StorageService) {}

  public removeProduct(product: any): void {
    const shoppingCart = this.getShoppingCart();
    const index = shoppingCart.findIndex(
      (item: any) => item.uid === product.uid
    );
    shoppingCart.splice(index, 1);
    this.storageService.setStorageItem({
      key: 'shoppingCart',
      value: JSON.stringify(shoppingCart),
      storageArea: 'sessionStorage',
    });
  }

  public reduceProduct(product: any): void {
    const shoppingCart = this.getShoppingCart();
    const index = shoppingCart.findIndex(
      (item: any) => item.uid === product.uid
    );
    shoppingCart[index].quantity--;
    this.storageService.setStorageItem({
      key: 'shoppingCart',
      value: JSON.stringify(shoppingCart),
      storageArea: 'sessionStorage',
    });
  }

  public addProduct(product: any): void {
    let shoppingCart: any = this.storageService.getStorageItem({
      key: 'shoppingCart',
      storageArea: 'sessionStorage',
    });
    shoppingCart = JSON.parse(shoppingCart) || [];

    if (!shoppingCart) {
      shoppingCart.push({
        uid: product.uid,
        quantity: 1,
        price: product.price,
        name: product.name,
      });
    } else {
      const findProduct = shoppingCart.find(
        (item: any) => item.uid === product.uid
      );
      if (!findProduct) {
        shoppingCart = [
          ...shoppingCart,
          {
            uid: product.uid,
            quantity: 1,
            price: product.price,
            name: product.name,
          },
        ];
      } else {
        findProduct.quantity++;
      }
    }

    this.storageService.setStorageItem({
      key: 'shoppingCart',
      value: JSON.stringify(shoppingCart),
      storageArea: 'sessionStorage',
    });
  }

  public getShoppingCart(): any {
    let shoppingCart = this.storageService.getStorageItem({
      key: 'shoppingCart',
      storageArea: 'sessionStorage',
    });
    shoppingCart = JSON.parse(shoppingCart) || [];

    return shoppingCart;
  }

  public deleteShoppingCart(): void {
    this.storageService.setStorageItem({
      key: 'shoppingCart',
      storageArea: 'sessionStorage',
      value: '[]',
    });
  }

  public addTicketUidMenu(uid: any): void {
    this.storageService.setStorageItem({
      key: 'uidMenu',
      value: JSON.stringify(uid),
      storageArea: 'sessionStorage',
    });
  }

  public getTicketUidMenu(): any {
    let uidMenu = this.storageService.getStorageItem({
      key: 'uidMenu',
      storageArea: 'sessionStorage',
    });
    uidMenu = JSON.parse(uidMenu) || '';

    return uidMenu;
  }

  public getUidEvent(): any {
    let uidEvent = this.storageService.getStorageItem({
      key: 'uidEvent',
      storageArea: 'sessionStorage',
    });
    uidEvent = JSON.parse(uidEvent) || '';

    return uidEvent;
  }

  public addUidEvent(uid: any): void {
    this.storageService.setStorageItem({
      key: 'uidEvent',
      value: JSON.stringify(uid),
      storageArea: 'sessionStorage',
    });
  }

  public addIdMarketing(id: any): void {
    this.storageService.setStorageItem({
      key: 'idMarketing',
      value: JSON.stringify(id),
      storageArea: 'sessionStorage',
    });
  }

  public getIdMarketing(): any {
    let uidEvent = this.storageService.getStorageItem({
      key: 'idMarketing',
      storageArea: 'sessionStorage',
    });
    uidEvent = JSON.parse(uidEvent) || '';
    return uidEvent;
  }
}
