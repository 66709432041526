<mat-toolbar class="fixed-top navbar">
    <!-- d-block d-sm-block d-md-block d-lg-none -->
    <button class=" d-block d-sm-block d-md-block d-lg-none" (click)="sidenavMenuAdmin.toggle()" mat-icon-button
        aria-label="Example icon-button with menu icon">
        <mat-icon>menu</mat-icon>
    </button>

    <!-- <span class="tittle-navbar-admin text-truncate" style="width: 50%; display: none;"></span> -->
    <!-- class="col-5 col-md-2 col-xl-1 col-xxl-1" -->
    <div (click)="goPage('/dashboard') " style=" width: 170px;">
        <img src="../../../assets/icons-web/logo.png" alt="" style="width: 100%;">
    </div>


    <span class="spacer"></span>


    <button style="margin-left: 10px;" mat-button *ngIf="this.authService.getUser().uid"
        [ngClass]="router.url.includes('/admin/events')?'menuSelect':'menuNoSelect' " class="d-none d-lg-block"
        [matMenuTriggerFor]="eventsMat">Eventos</button>




    <mat-menu #eventsMat="matMenu">


        <mat-selection-list [multiple]="false" style="margin: 5px;">


            <button [ngClass]="router.url.includes('/admin/events-future')?'menuSelect':'menuNoSelect' " mat-menu-item
                (click)="goPage('/events-future') ">
                <mat-icon>event</mat-icon>
                <span>Eventos Futuros</span>
            </button>
            <button [ngClass]="router.url.includes('/admin/events-past')?'menuSelect':'menuNoSelect' " mat-menu-item
                (click)="goPage('/events-past') ">
                <mat-icon>event_available</mat-icon>
                <span>Eventos Pasados</span>
            </button>

            <button [ngClass]="router.url.includes('/admin/events-delete')?'menuSelect':'menuNoSelect' " mat-menu-item
                (click)="goPage('/events-past') ">
                <mat-icon>delete</mat-icon>
                <span>Eventos Eliminados</span>
            </button>


        </mat-selection-list>

    </mat-menu>

    <button (click)="goPage('/menus') " style="margin-left: 10px;" mat-button *ngIf="this.authService.getUser().uid"
        [ngClass]="router.url.includes('/admin/menus')?'menuSelect':'menuNoSelect' "
        class="d-none d-lg-block">Cartas</button>


    <button (click)="goPage('/staff-qr') " style="margin-left: 10px;" mat-button *ngIf="this.authService.getUser().uid"
        [ngClass]="router.url.includes('/admin/staff-qr')?'menuSelect':'menuNoSelect' "
        class="d-none d-lg-block">Trabajadores</button>

    <button (click)="goPage('/clients') " style="margin-left: 10px;" mat-button *ngIf="this.authService.getUser().uid"
        [ngClass]="router.url.includes('/admin/clients')?'menuSelect':'menuNoSelect' "
        class="d-none d-lg-block">Clientes</button>

    <a style="margin-left: 10px;" *ngIf="this.authService.getUser().uid" class="nav-link d-none d-lg-block"
        [matMenuTriggerFor]="profile">
        <div style="    width: 36px;
        background: white;
        border-radius: 10px; box-shadow: 0px 0px 10px 1px #5271ff;">
            <img style="    width: 100%; border-radius: 10px;" [src]="this.authService.getUser().photoURL">
        </div>
    </a>



    <mat-menu #profile="matMenu">
        <div style="color: white;">
            <p class="m-0 text-center">Bienvenido</p>
            <p class=" m-0  text-center"> {{this.authService.getUser().displayName}}</p>
        </div>

        <mat-selection-list [multiple]="false" style="margin: 5px;">




            <mat-list-option (click)="goPage('/banks') " *ngIf="this.authService.getUser().uid"
                [ngClass]="router.url.includes('/admin/banks')?'menuSelect':'menuNoSelect' ">
                <mat-icon mat-list-icon>savings</mat-icon>
                <div mat-line>Cuenta Bancaria</div>
            </mat-list-option>


            <mat-list-option (click)="logout()" *ngIf="this.authService.getUser().uid">
                <mat-icon mat-list-icon>logout</mat-icon>
                <div mat-line>Salir</div>
            </mat-list-option>


            <mat-divider class="mt-2" *ngIf="this.authService.getUser().uid"></mat-divider>
            <div mat-subheader *ngIf="this.authService.getUser().isAdmin ||this.authService.getUser().isWorker">
                Otros Sistemas</div>


            <mat-list-option (click)="goOtherPage('/workers',sidenavMenuAdmin) "
                [ngClass]="{'menuSelect':router.url.includes('/workers')}">
                <mat-icon mat-list-icon>engineering</mat-icon>
                <div mat-line>Workers</div>
            </mat-list-option>

            <mat-list-option (click)="goOtherPage('/',sidenavMenuAdmin) ">
                <mat-icon mat-list-icon>home</mat-icon>
                <div mat-line>On Ticket Chile </div>
            </mat-list-option>

        </mat-selection-list>

    </mat-menu>

</mat-toolbar>