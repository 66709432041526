import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private url = environment.urlBackend;
  private urlAdmin = environment.urlBackendAdmin;
  private urlWorker = environment.urlBackendWorker;

  constructor(private http: HttpClient) {}

  public async getUser() {
    const authToken = await firebase!.auth()!.currentUser!.getIdToken();
    console.log(authToken);

    return this.http
      .get(this.url + 'users', {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          return of(error.error);
        })
      );
  }

  public async getUserAdmin() {
    const authToken = await firebase!.auth()!.currentUser!.getIdToken();
    console.log(authToken);

    return this.http
      .get(this.urlAdmin + 'users', {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          return of(error.error);
        })
      );
  }

  public async saveAccBankUserAdmin(value: any) {
    const authToken = await firebase!.auth()!.currentUser!.getIdToken();

    return this.http
      .post(this.urlAdmin + 'users/banks', value, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          return of(error.error);
        })
      );
  }

  public async getUserWorker() {
    const authToken = await firebase!.auth()!.currentUser!.getIdToken();
    console.log(authToken);

    return this.http
      .get(this.urlWorker + 'users', {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          return of(error.error);
        })
      );
  }
}
