import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import packageInfo from '../../../../package.json';

@Component({
  selector: 'app-home-footer',
  templateUrl: './home-footer.component.html',
  styleUrls: ['./home-footer.component.scss'],
})
export class HomeFooterComponent implements OnInit {
  version: any = '';
  name: any = '';

  categories: any = [
    { name: 'Fiesta', id: 'fiesta' },
    { name: 'Concierto', id: 'concierto' },
    { name: 'Sunset', id: 'sunset' },
  ];
  constructor(public router: Router) {}

  ngOnInit() {
    this.version = packageInfo.version;
    this.name = 'On Ticket Chile';
  }

  navigation(type: any) {
    this.router.navigate(['events', { type }]);
  }
  goPage(page: string) {
    this.router.navigate([page]);
  }

  goSendWstp(string: any) {
    window.open(string, '_blank')!.focus();
  }
}
