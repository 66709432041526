<div class="example-container">

  <app-toolbar-home-workers *ngIf="
    router.url != '/workers/login'
  &&
   !router.url.includes('/workers/tables/')
  " [sidenavMenuWorkers]="sidenavMenuWorkers">
  </app-toolbar-home-workers>
  <!-- <div class="container" style="margin-top: 4rem !important;">
    <router-outlet></router-outlet>
</div> -->

  <mat-sidenav-container>

    <mat-sidenav #sidenavMenuWorkers position="start" class="footer d-flex " (closed)="navclose()" (opened)="navOpen()"
      style="margin-top: 3.5rem !important; ">
      <div class="container mt-3">

        <div class="col-12 mt-3 mb-3 container">
          <mat-icon style="float: right;" (click)="closeSideNav(sidenavMenuWorkers)">close
          </mat-icon>
        </div>

        <div class="row d-flex justify-content-center align-items-center col-12" style="padding: 16px;">
          <div class="col-3 ">
            <img class="card-img-top rounded" [src]="this.authService.getUser().photoURL">
          </div>
          <div class="col-9">
            <p class="m-0">Bienvenido</p>
            <p class=" m-0"> {{this.authService.getUser().displayName}}</p>
          </div>
        </div>

        <mat-selection-list [multiple]="false">

          <!--
                    <mat-list-option (click)="goPage('/stores',sidenavMenuWorkers) "
                        [ngStyle]="{'background-color':router.url == '/workers/stores'? '#c2185b63' : 'unset' }">
                        <mat-icon mat-list-icon>storefront</mat-icon>
                        <div mat-line>Locales</div>
                    </mat-list-option>

                    <mat-list-option (click)="goPage('/menus',sidenavMenuWorkers) "
                        [ngStyle]="{'background-color':router.url == '/workers/menus'? '#c2185b63' : 'unset' }">

                        <mat-icon mat-list-icon>menu_book</mat-icon>
                        <div mat-line>Cartas</div>
                    </mat-list-option>

                    <mat-list-option (click)="goPage('/events',sidenavMenuWorkers) "
                        [ngStyle]="{'background-color':router.url == '/workers/events'? '#c2185b63' : 'unset' }">

                        <mat-icon mat-list-icon>celebration</mat-icon>
                        <div mat-line>Eventos</div>
                    </mat-list-option> -->
          <!-- <mat-list-option>
                        <mat-icon mat-list-icon>payment</mat-icon>
                        <div mat-line>Cuenta Bancaria</div>
                    </mat-list-option> -->

          <!-- <div mat-subheader>Historial</div> -->


          <!-- <mat-list-option>
                        <mat-icon mat-list-icon>format_list_bulleted</mat-icon>
                        <div mat-line>Clientes</div>
                    </mat-list-option>

                    <mat-divider></mat-divider> -->

          <div mat-subheader>Trabajador Accesos</div>



          <mat-list-option (click)="goPage('/cash-register',sidenavMenuWorkers) "
            [ngClass]="{'menuSelect':router.url == '/workers/cash-register' }">
            <mat-icon mat-list-icon>point_of_sale</mat-icon>
            <div mat-line>Caja </div>
          </mat-list-option>


          <mat-list-option (click)="goPage('/events',sidenavMenuWorkers) "
            [ngClass]="{'menuSelect':router.url == '/workers/events' }">
            <mat-icon mat-list-icon>barcode_reader</mat-icon>
            <div mat-line>Lectura Accesos</div>
          </mat-list-option>

          <mat-list-option (click)="goPage('/history-access',sidenavMenuWorkers) "
            [ngClass]="{'menuSelect':router.url.includes('/workers/history-access') }">
            <mat-icon mat-list-icon>history</mat-icon>
            <div mat-line>Hitorial Accesos</div>
          </mat-list-option>

          <div mat-subheader>Trabajador Cartas</div>
          <mat-list-option (click)="goPage('/menus',sidenavMenuWorkers) "
            [ngClass]="{'menuSelect':router.url == '/workers/menus' }">

            <mat-icon mat-list-icon>barcode_reader</mat-icon>
            <div mat-line>Lectura Menu</div>
          </mat-list-option>

          <mat-list-option (click)="goPage('/history-bar',sidenavMenuWorkers) "
            [ngClass]="{'menuSelect':router.url.includes('/workers/history-bar') }">
            <mat-icon mat-list-icon>history</mat-icon>
            <div mat-line>Hitorial Menú</div>
          </mat-list-option>

          <div mat-subheader>Cuenta</div>



          <mat-list-option (click)="goPage('/dashboard',sidenavMenuWorkers) "
            [ngClass]="{'menuSelect':router.url == '/workers/dashboard' }">
            <mat-icon mat-list-icon>home</mat-icon>
            <div mat-line>Home</div>
          </mat-list-option>







          <mat-list-option>
            <mat-icon mat-list-icon>qr_code</mat-icon>
            <div mat-line>Editar Perfil</div>
          </mat-list-option>

          <mat-list-option (click)="logout(); sidenavMenuWorkers.toggle()">
            <mat-icon mat-list-icon>logout</mat-icon>
            <div mat-line>Salir</div>
          </mat-list-option>

          <mat-divider class="mt-2"></mat-divider>

          <div mat-subheader>Otros Sistemas</div>

            <mat-list-option (click)="goPage('/tables',sidenavMenuWorkers) "
            *ngIf="this.authService.getUser().isAdmin">
            <mat-icon mat-list-icon>install_mobile</mat-icon>
            <div mat-line>Configurar Tablet para Mesa</div>
          </mat-list-option>


          <mat-list-option (click)="goOtherPage('/admin',sidenavMenuWorkers) "
            *ngIf="this.authService.getUser().isAdmin">
            <mat-icon mat-list-icon>admin_panel_settings</mat-icon>
            <div mat-line>Admin</div>
          </mat-list-option>

          <mat-list-option (click)="goOtherPage('/',sidenavMenuWorkers) ">
            <mat-icon mat-list-icon>home</mat-icon>
            <div mat-line>App </div>
          </mat-list-option>

        </mat-selection-list>
      </div>

      <!-- <div class="footer">



                <mat-card class="  button-pay">
                    <div class="row d-flex justify-content-center align-items-center ">
                        <button class="col-10 " (click)="logout(); sidenavMenuWorkers.toggle()" color="warn"
                            mat-stroked-button>
                            <mat-icon>logout</mat-icon>
                            <span>Salir</span>
                        </button>
                    </div>

                </mat-card>




            </div> -->
    </mat-sidenav>


    <mat-sidenav-content [ngStyle]="{'margin-top':(router.url != '/workers/login'   &&
   !router.url.includes('/workers/tables/'))? '3.5rem' : 'unset' }">

      <h1 *ngIf="router.url != '/workers/login'   &&
   !router.url.includes('/workers/tables/')" class=" uppercase text-center tittle-navbar-admin mt-2"></h1>

      <router-outlet class="min-height-100"></router-outlet>

      <app-home-footer *ngIf="
   !router.url.includes('/workers/tables/')
  "></app-home-footer>


    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<div *ngIf="viewBack" class=" backButtom">
  <button (click)="clickBack()" color="primary" matBadgePosition="after" matBadgeColor="warn" mat-mini-fab
    aria-label="">
    <mat-icon>arrow_back</mat-icon>


  </button>
</div>
