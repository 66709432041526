<mat-toolbar class="fixed-top navbar">
  <button
    class="d-block d-sm-block d-md-block d-lg-none"
    (click)="sidenavMenu.toggle()"
    mat-icon-button
    aria-label="Example icon-button with menu icon"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <span
    class="tittle-navbar text-truncate"
    style="width: 50%; display: none"
  ></span>
  <!-- class="col-5 col-md-2 col-xl-1 col-xxl-1" -->
  <div (click)="goPage('/welcome')" style="width: 170px" class="pointer">
    <img src="../../../assets/icons-web/logo.png" alt="" style="width: 100%" />
  </div>

  <span class="spacer"></span>

  <button
    (click)="goPage('/events')"
    [ngClass]="router.url.includes('/events') ? 'menuSelect' : 'menuNoSelect'"
    class="d-none d-lg-block"
    mat-button
    style="margin-left: 10px"
  >
    Eventos
  </button>

  <!-- <button (click)="goPage('/menus') " [ngClass]="router.url.includes('/menus')?'menuSelect':'menuNoSelect' "
        class="d-none d-lg-block" mat-button style="margin-left: 10px;">Cartas
    </button> -->

  <!-- <button
    (click)="goPage('/services')"
    [ngClass]="router.url.includes('/services') ? 'menuSelect' : 'menuNoSelect'"
    class="d-none d-lg-block"
    mat-button
    style="margin-left: 10px"
  >
    Servicios
  </button> -->

  <button
    style="margin-left: 10px"
    mat-button
    *ngIf="!this.authService.getUser().uid"
    (click)="login()"
  >
    Iniciar Sesión
  </button>
  <button
    style="margin-left: 10px"
    mat-stroked-button
    color="warn"
    class="d-none d-lg-block"
    (click)="goPage('/admin')"
    [ngClass]="router.url.includes('/admin') ? 'menuSelect' : 'menuNoSelect'"
  >
    Crear Evento
  </button>

  <a
    style="margin-left: 10px"
    *ngIf="this.authService.getUser().uid"
    class="nav-link d-none d-lg-block pointer"
    [matMenuTriggerFor]="profile"
  >
    <div
      style="
        width: 36px;
        height: 36px;
        background: white;
        border-radius: 15px;
        box-shadow: 0px 0px 10px 1px #5271ff;
      "
    >
      <img
        style="width: 100%; border-radius: 10px"
        [src]="this.authService.getUser().photoURL"
      />
    </div>
  </a>

  <mat-menu #profile="matMenu">
    <div style="color: white">
      <p class="m-0 text-center">Bienvenido</p>
      <p class="m-0 text-center">
        {{ this.authService.getUser().displayName }}
      </p>
    </div>

    <mat-selection-list [multiple]="false" style="margin: 5px">
      <!--
            <mat-list-option (click)="goPage('/business') "
                [ngClass]="router.url.includes('/business')?'menuSelect':'menuNoSelect' ">
                <mat-icon mat-list-icon>event</mat-icon>
                <div mat-line>Publicar un evento</div>
            </mat-list-option>

            <mat-divider class="mt-2"></mat-divider> -->

      <mat-list-option
        (click)="goPage('/profile')"
        *ngIf="this.authService.getUser().uid"
        [ngClass]="{ menuSelect: router.url.includes('/profile') }"
      >
        <mat-icon mat-list-icon>account_circle</mat-icon>
        <div mat-line>Perfil</div>
      </mat-list-option>

      <mat-list-option
        (click)="goPage('/tickets-bar')"
        *ngIf="this.authService.getUser().uid"
        [ngClass]="{ menuSelect: router.url.includes('/tickets-bar') }"
      >
        <mat-icon mat-list-icon>local_bar</mat-icon>
        <div mat-line>Tickets Barra</div>
      </mat-list-option>

      <mat-list-option
        (click)="goPage('/tickets-access')"
        *ngIf="this.authService.getUser().uid"
        [ngClass]="{ menuSelect: router.url.includes('/tickets-access') }"
      >
        <mat-icon mat-list-icon>confirmation_number</mat-icon>
        <div mat-line>Tickets de Accesos</div>
      </mat-list-option>
      <mat-list-option
        (click)="logout()"
        *ngIf="this.authService.getUser().uid"
      >
        <mat-icon mat-list-icon>logout</mat-icon>
        <div mat-line>Salir</div>
      </mat-list-option>

      <mat-divider
        class="mt-2"
        *ngIf="this.authService.getUser().uid"
      ></mat-divider>
      <div
        mat-subheader
        *ngIf="
          this.authService.getUser().isAdmin ||
          this.authService.getUser().isWorker
        "
      >
        Otros Sistemas
      </div>

      <mat-list-option
        (click)="goPage('/admin')"
        *ngIf="this.authService.getUser().isAdmin"
      >
        <mat-icon mat-list-icon>admin_panel_settings</mat-icon>
        <div mat-line>Admin</div>
      </mat-list-option>

      <mat-list-option
        (click)="goPage('/workers')"
        *ngIf="this.authService.getUser().isWorker"
      >
        <mat-icon mat-list-icon>engineering</mat-icon>
        <div mat-line>Workers</div>
      </mat-list-option>
    </mat-selection-list>
  </mat-menu>

  <!-- <div class="col-1" style="background: white;">

        <img class="card-img-top rounded" style="width: 100%;" [src]="this.authService.getUser().photoURL">
    </div> -->

  <button
    *ngIf="this.authService.getUser().uid"
    mat-icon-button
    (click)="refresh()"
    class="d-block d-sm-block d-md-block d-lg-none"
    aria-label="Example icon-button with heart icon"
  >
    <mat-icon>refresh</mat-icon>
  </button>

  <button
    *ngIf="this.authService.getUser().uid"
    mat-icon-button
    (click)="share()"
    class="d-block d-sm-block d-md-block d-lg-none"
    aria-label="Example icon-button with heart icon"
  >
    <mat-icon>share</mat-icon>
  </button>

  <button
    *ngIf="
      this.authService.getUser().uid &&
      (this.router.url.includes('/menus/') ||
        this.router.url.includes('/events/'))
    "
    id="checkoutButtonFloat"
    (click)="sidenavCheckOut.toggle()"
    color="primary"
    matBadge="{{ quantityProduct }}"
    matBadgePosition="after"
    matBadgeSize="small"
    matBadgeColor="warn"
    mat-mini-fab
    aria-label=""
    class="no-shadow"
  >
    <mat-icon>shopping_cart_checkout</mat-icon>
  </button>
</mat-toolbar>

<!-- <div (click)="sidenavCheckOut.toggle()"
    *ngIf="this.authService.getUser().uid && (this.router.url.includes('/menus/')||this.router.url.includes('/events/'))"
    class=" checkoutButton shadow-div shadow-div text-center " style="    display: flex;
    justify-content: center;
    align-items: center;">

    <div class="col-12 mt-1">
        <button id="checkoutButtonFloat" style="" color="primary" matBadge="{{quantityProduct}}"
            matBadgePosition="after"  matBadgeColor="warn" mat-mini-fab aria-label="" class="col-12 no-shadow  ">
            <mat-icon>shopping_cart_checkout</mat-icon>

        </button>
        <p class="no-margin">checkout</p>
    </div>




</div> -->
