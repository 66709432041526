import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { map } from 'rxjs';
import { ShoppingService } from './shopping.service';

import { SpinnerService } from './spinner.service';
import { StorageService } from './storage.service';
import { UsersService } from './users.service';

import { getAnalytics, setUserProperties, setUserId } from 'firebase/analytics';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private auth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private spinnerService: SpinnerService,
    private shoppingService: ShoppingService,
    private usersService: UsersService,
    private storageService: StorageService
  ) {
    this.auth.authState.subscribe(async (user: any) => {
      this.spinnerService.showSpinner();

      if (!user) {
        this.spinnerService.hideSpinner();
        if (router.url.includes('admin/')) {
          this.router.navigate(['admin/login']);
          return this.logout();
        }
        if (router.url.includes('workers/')) {
          this.router.navigate(['workers/login']);
          return this.logout();
        }
        return this.logout();
      }

      const analytics = getAnalytics();
      setUserProperties(analytics, { email: user.email });
      // setUserId(analytics, user.email);

      if (router.url.includes('admin/')) {
        (await this.usersService.getUserAdmin()).subscribe((data: any) => {
          console.log('login Admins');
          this.spinnerService.hideSpinner();
          if (!data.result) {
            alert(data.message + ' ' + data.code);
            this.router.navigate(['admin/login']);
            return this.logout();
          }

          this.storageService.setStorageItem({
            key: 'users',
            value: `${JSON.stringify({ ...user, ...data.result })}`,
            storageArea: 'sessionStorage',
          });

          if (router.url === '/admin/login') {
            return this.router.navigate(['admin/dashboard']);
          }
        });
        return true;
      }

      if (router.url.includes('workers/')) {
        (await this.usersService.getUserWorker()).subscribe((data: any) => {
          console.log('login workers');

          this.spinnerService.hideSpinner();
          if (!data.result) {
            alert(data.message + ' ' + data.code);
            this.router.navigate(['workers/login']);
            return this.logout();
          }

          this.storageService.setStorageItem({
            key: 'users',
            value: `${JSON.stringify({ ...user, ...data.result })}`,
            storageArea: 'sessionStorage',
          });

          if (router.url === '/workers/login') {
            return this.router.navigate(['workers/dashboard']);
          }
        });
        return true;
      }

      (await this.usersService.getUser()).subscribe(async (data: any) => {
        console.log('login user');
        await this.storageService.setStorageItem({
          key: 'users',
          value: `${JSON.stringify({ ...user, ...data.result })}`,
          storageArea: 'sessionStorage',
        });
        this.spinnerService.hideSpinner();
        return true;
      });
    });
  }

  getUser() {
    return JSON.parse(sessionStorage.getItem('users')!) || {};
  }

  getLoginStatus() {
    return this.auth.authState.pipe(map((user: any) => !!user));
  }
  getUserFirebase() {
    return this.auth.authState.pipe(map((user: any) => user));
  }
  loginGoogle() {
    this.spinnerService.showSpinner();
    this.auth
      .signInWithPopup(
        new firebase.auth.GoogleAuthProvider().setCustomParameters({
          prompt: 'select_account',
        })
      )
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        this.spinnerService.hideSpinner();
        window.alert(error);
      });
  }

  logout() {
    this.spinnerService.showSpinner();
    this.auth.signOut();
    this.shoppingService.deleteShoppingCart();

    this.storageService.deleteStorageItem({
      key: 'users',
      storageArea: 'sessionStorage',
    });

    if (this.router.url.includes('admin/')) {
      this.router.navigate(['admin/login']);
    } else {
      if (this.router.url.includes('workers/')) {
        this.router.navigate(['workers/login']);
      } else {
        if (
          this.router.url.includes('tickets') ||
          this.router.url.includes('profile')
        ) {
          this.router.navigate(['']);
        }
      }
    }

    this.spinnerService.hideSpinner();
  }
}
