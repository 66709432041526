<h2 mat-dialog-title>
  Iniciar Sesión
</h2>





<mat-dialog-content class="mat-typography row  d-flex justify-content-center" style="    ">
  <p class="text-center">Es necesario iniciar sesion para ejecutar esta acción</p>

  <div class="  mb-2 col-12 col-md-12 d-flex justify-content-center  align-items-center row">
    <button (click)="login()" color="warn" mat-raised-button style="background-color: white;" class="col-12 col-md-12">
      <span><img style="margin: 5px;width: auto;" src="../assets/imgs/icons/common/google.svg"></span>
      <span style="
    color: black;"> Google</span>
    </button>
  </div>


</mat-dialog-content>



<mat-dialog-actions align="end">

  <button mat-button type="button" (click)="closeDialog(false)">Cerrar</button>
</mat-dialog-actions>