import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../environments/environment';

import { ToolbarHomeComponent } from './components/toolbar-home/toolbar-home.component';
import { ToolbarAdminHomeComponent } from './components-admin/toolbar-admin-home/toolbar-admin-home.component';

import { MateriAlllModule } from './material.module';
import { MyHttpInterceptor } from './interceptors/http.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { WorkersLayoutComponent } from './layouts/workers-layout/workers-layout.component';
import { ToolbarWorkersHomeComponent } from './components-workers/toolbar-workers-home/toolbar-workers-home.component';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HomeFooterComponent } from './components/home-footer/home-footer.component';

import { AngularFireModule } from '@angular/fire/compat';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
  CONFIG,
  DEBUG_MODE,
  APP_NAME,
  APP_VERSION,
} from '@angular/fire/compat/analytics';

import packageInfo from '../../package.json';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';

@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    AdminLayoutComponent,
    WorkersLayoutComponent,
    ToolbarHomeComponent,
    ToolbarAdminHomeComponent,
    ToolbarWorkersHomeComponent,
    HomeFooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    MateriAlllModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,

    ModalModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.pwa,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ImageCropperModule,
    AngularFireStorageModule,
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    // {
    //   provide: CONFIG,
    //   useValue: {
    //     send_page_view: true,
    //     allow_ad_personalization_signals: true,
    //   },
    // },
    { provide: DEBUG_MODE, useValue: environment.debugFirebase },
    { provide: APP_NAME, useValue: environment.firebase.projectId },
    { provide: APP_VERSION, useValue: packageInfo.version },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
