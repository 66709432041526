import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<LoginModalComponent>,
    private authService: AuthService
  ) {}

  ngOnInit() {}

  closeDialog(action: any) {
    this.dialogRef.close(action);
  }
  login() {
    this.authService.loginGoogle();
    this.closeDialog(true);
  }
}
