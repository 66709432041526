import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ShoppingService } from 'src/app/services/shopping.service';
import { filter, pluck } from 'rxjs/operators';
import { StorageService } from 'src/app/services/storage.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoginModalComponent } from 'src/app/modals/login-modal/login-modal.component';
@Component({
  selector: 'app-toolbar-home',
  templateUrl: './toolbar-home.component.html',
  styleUrls: ['./toolbar-home.component.scss'],
})
export class ToolbarHomeComponent implements OnInit {
  userLogin: boolean = false;
  @Input() sidenavCheckOut: any;
  @Input() sidenavMenu: any;

  quantityProduct: any = 0;

  constructor(
    public authService: AuthService,
    private storageService: StorageService,
    private shopping: ShoppingService,
    public router: Router,
    private modal: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.calculateTotalCart(this.shopping.getShoppingCart());
    const viewCart$ = this.storageService.storageChange$.pipe(
      filter(({ storageArea }) => storageArea === 'sessionStorage'),
      filter(({ key }) => key === 'shoppingCart'),
      pluck('value')
    );

    viewCart$.subscribe((value) => {
      this.calculateTotalCart(JSON.parse(value));
    });
  }

  calculateTotalCart(viewCart: any) {
    this.quantityProduct = viewCart.reduce(
      (acc: any, item: any) => acc + item.quantity,
      0
    );
    this.quantityProduct =
      this.quantityProduct === 0 ? 0 : this.quantityProduct;
  }

  login() {
    const dialogRef = this.modal.open(LoginModalComponent, {
      data: {},
      disableClose: true,
      // width: '100vw',
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
      }
    });
  }
  logout() {
    this.authService.logout();
  }

  share() {
    let element = document.querySelector('.tittle-navbar') as HTMLElement;
    let data = {
      files: [],
      text: element.innerHTML,
      url: window.location.href,
      title: element.innerHTML,
    };
    navigator.share(data);
  }

  refresh() {
    location.reload();
  }

  goPage(page: string) {
    this.router.navigate([page]);
  }
}
