<h2 mat-dialog-title>
  Checkout
</h2>





<mat-dialog-content class="mat-typography row  d-flex justify-content-center">




  <div *ngIf="loading" class=" row col-12 d-flex justify-content-center mt-5">
    <app-spinner-loading></app-spinner-loading>
  </div>


  <div *ngIf="!loading" class="col-12  d-flex justify-content-center row  no-padding">



    <div style="background: #f44336;color: white;padding: 10px;border-radius: 10px;"
      class="col-12 row d-flex justify-content-center  mt-2 mb-4 text-center no-padding">
      <p class="no-margin text-truncate">Los tickets quedaran en la cuenta</p>
      <p class="no-margin text-truncate"><b> {{user.email}}</b></p>
    </div>



    <div class="col-12 col-md-6   row d-flex justify-content-center mt-2 mb-4  no-padding text-center">
      <h3>
        Selecione metodo de pago
      </h3>

      <!-- <p class="text-center underover"> <b> Selecione metodo de pago </b></p> -->
      <div class="col-8 col-md-4  row d-flex justify-content-center mt-2 mb-2 no-padding">
        <mat-checkbox [(ngModel)]="selectPay" color="accent" class="example-margin"><img style="width: 100%;"
            src="../../../assets/typePay/logo_mercado.png" alt=""></mat-checkbox>
      </div>

      <!-- <mat-checkbox color="accent" class="example-margin" [disabled]="true"><img style="width: 100%;"
          src="../../../assets/typePay/logo_webpay.png" alt=""></mat-checkbox> -->
    </div>

    <div class="col-12 col-md-6   row d-flex justify-content-center  mt-2 mb-4  no-padding text-center">
      <!-- <p class="text-center underover"> <b> Estas Comprando </b></p> -->
      <h3>
        Estas Comprando
      </h3>
      <div *ngIf="viewCart.length" class="table-responsive  no-padding ">


        <table class="table ">
          <thead>
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Precio</th>
              <th scope="col">Cantidad</th>
              <th scope="col">total</th>
            </tr>
          </thead>
          <tbody>



            <tr *ngFor=" let ticket of viewCart">
              <td>
                <button mat-button>
                  {{ticket.name}}
                </button>
              </td>
              <td>
                <button mat-button>
                  {{ticket.price}}
                </button>
              </td>
              <td>
                <button mat-button>
                  {{ticket.quantity}}
                </button>
              </td>

              <td>
                <button mat-button>
                  {{ticket.quantity *ticket.price}}
                </button>
              </td>


            </tr>


          </tbody>
        </table>


      </div>

    </div>








  </div>


</mat-dialog-content>



<mat-dialog-actions style="    background: #feff0014;
color: black;
padding: 10px;
 margin-left: -24px;
    margin-right: -24px;
    margin-top: 24px; border-radius: 20px 20px 0px 0px;" align="end">

  <div class="col-12 col-md-6  no-padding mb-1">
    <p class="no-margin text-center " style="font-size: 12px;"> <b> Por favor verifica tus datos antes de realizar tu
        compra. </b>
    </p>
  </div>

  <div class="col-12 col-md-6 no-margin  no-padding row ">
    <p class="no-margin col-12 col-md-12">SubTotal : ${{ totalCart |number}}</p>
    <p class="no-margin  col-12 col-md-12">Cargo por Servicio : ${{ totalService|number}}</p>
    <p class="no-margin col-12 col-md-12"><b>Total : ${{ totalCart+totalService|number}} </b> </p>
  </div>

  <div class="mt-3">
    <button mat-button type="button" [disabled]=" goPayBoolean" (click)="closeDialog(false)">Cerrar</button>
    <button mat-button type="button" [disabled]="!selectPay || goPayBoolean" color="warn" mat-raised-button
      (click)="goPay()">Ir
      Pagar</button>
  </div>



</mat-dialog-actions>