import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SpinnerService } from './spinner.service';
import firebase from 'firebase/compat/app';
import { ShoppingService } from './shopping.service';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  private url = environment.urlBackend;

  constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    public shopping: ShoppingService
  ) {}

  public async checkoutBar(data: any) {
    const authToken = await firebase!.auth()!.currentUser!.getIdToken();

    const items: Array<any> = [];

    data.forEach((element: any) => {
      items.push({
        uid: element.uid,
        title: element.name,
        unit_price: Number(element.price),
        quantity: element.quantity,
      });
    });

    const uidMenu = await this.shopping.getTicketUidMenu();

    return this.http
      .post(
        this.url + 'checkout/bar',
        { items, idMenu: uidMenu },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          return of(error.error);
        })
      );
  }

  public async checkoutAccess(tickets: any) {
    const authToken = await firebase!.auth()!.currentUser!.getIdToken();
    const items: Array<any> = [];

    tickets.forEach((element: any) => {
      items.push({
        uid: element.uid,
        title: element.name,
        unit_price: Number(element.price),
        quantity: element.quantity,
      });
    });

    const idEvent = await this.shopping.getUidEvent();
    const idMarketing = this.shopping.getIdMarketing();

    return this.http
      .post(
        this.url + 'checkout/access',
        { items, idEvent, tagMarketing: idMarketing },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          return of(error.error);
        })
      );
  }
}
