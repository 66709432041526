<div class=" background-footer d-flex justify-content-center    ">


  <div class="col-12 col-md-10 no-padding container mt-5 mb-5 d-flex justify-content-center row">




    <div class="col-12  row d-flex  mb-5 justify-content-center ">

      <div class="col-12 col-md-4 mt-3">
        <h3 class="text-footer">CATEGORIAS</h3>


        <p class="text-footer" (click)="navigation(categorie.id)" *ngFor=" let categorie of this.categories">
          {{categorie.name}}</p>



      </div>


      <div class="col-12 col-md-4 mt-3">
        <h3 class="text-footer">TU EVENTO</h3>

        <mat-list role="list">
          <p (click)="goPage('/admin') " class="text-footer">¿Eres Productor?</p>
          <p (click)="goPage('/admin') " class="text-footer">Crear Evento</p>
          <p class="text-footer">Términos y Condiciones</p>
        </mat-list>

      </div>


      <div class="col-12 col-md-4 mt-3">
        <h3 class="text-footer">ON TICKET CHILE</h3>

        <mat-list role="list">
          <p (click)="goPage('/admin') " class="text-footer">¿Que es On Ticket Chile?</p>
          <p (click)="goPage('/admin') " class="text-footer">Preguntas Frecuentes</p>
          <p (click)="goPage('/admin') " class="text-footer">Contacto</p>
          <p (click)="goPage('/workers') " class="text-footer">¿Eres un Trabajador ?</p>
        </mat-list>

      </div>
    </div>

    <div class="col-12  row d-flex justify-content-center align-items-center  ">

      <div class="col-8 col-md-8 pointer">
        <div (click)="goPage('/welcome') " style=" width: 170px;">
          <img src="../../../assets/icons-web/logo.png" alt="" style="width: 100%;">
        </div>
        <font style="font-size: 11px;"> Sal y vive experiencias extraordinarias. </font>
      </div>

      <div class="col-4 col-md-4 ">
        <div (click)="goSendWstp('https://www.instagram.com/onticketchile/')" style=" width: 25px; margin-left: 10px; "
          class="
          float-end pointer">
          <img src="../../../assets/rrss/instagram.png" alt="" style="width: 100%;">
        </div>

        <div (click)="goSendWstp('https://wa.me/56984230836')" style=" width: 25px; margin-left: 10px;
          margin-right: 10px;" class="float-end pointer">
          <img src="../../../assets/rrss/whatsapp.png" alt="" style="width: 100%;">
        </div>
      </div>



    </div>

    <div class="col-12  row d-flex justify-content-center align-items-center mt-5  ">
      <div class="col-12 row d-flex justify-content-center  ">

        <p class="no-padding" style="color: white; text-align: justify;     font-size: 12px;">
          Importante: {{name}} no se
          hace responsable de
          la calidad o
          satisfacción de los eventos publicados. {{name}} es un
          sistema que presta el servicio de venta de entradas online. Al usar este sitio usted acepta los términos y
          condiciones de la aplicación.</p>
      </div>


      <div class="col-12 d-flex justify-content-center mt-5" style="color: white;
      ">

        <div style=" width: 200px;background: white;">
          <img src="../../../assets/typePay/logo_mercado.png" alt="" style="width: 100%;">
        </div>

      </div>

      <div class="col-12 text-center mt-5" style="color: white;
        "> Copyright © 2023 {{name}} </div>

    </div>


  </div>


</div>
