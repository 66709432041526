import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class SpinnerService {
  constructor(private storageService: StorageService) {}

  public showSpinner(): void {
    this.storageService.setStorageItem({
      key: 'loadingSpinner',
      value: 'show',
      storageArea: 'sessionStorage',
    });
  }

  public hideSpinner(): void {
    this.storageService.setStorageItem({
      key: 'loadingSpinner',
      value: 'hide',
      storageArea: 'sessionStorage',
    });
  }
}
