import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { StorageService } from 'src/app/services/storage.service';
import { filter, pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ShoppingService } from 'src/app/services/shopping.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CheckoutService } from 'src/app/services/checkout.service';
import { UsersService } from 'src/app/services/users.service';
import { MatDialog } from '@angular/material/dialog';
import { SelectTypePayModalComponent } from 'src/app/modals/select-type-pay-modal/select-type-pay-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss'],
})
export class HomeLayoutComponent implements OnInit {
  viewCart$!: Observable<string>;
  viewCart: any = [];
  totalCart!: number;
  totalService!: number;

  sideMenuIsOpen = false;
  sideCheckoutIsOpen = false;
  viewBack = false;
  isBack = false;

  user: any = {};
  userIsLogin = false;

  quantityAccept: any = 10;

  private history: string[] = [];

  constructor(
    public authService: AuthService,
    private storageService: StorageService,
    public shopping: ShoppingService,
    public checkoutService: CheckoutService,
    public spinnerService: SpinnerService,
    public router: Router,
    public usersService: UsersService,
    public modal: MatDialog
  ) {}

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    let navbar = document.querySelector('.navbar') as HTMLElement;

    if (window.pageYOffset > navbar.clientHeight) {
      // navbar.classList.add('navbar-inverse');
    } else {
      // navbar.classList.remove('navbar-inverse');
    }

    // if (!this.router.url.includes('/welcome')) {
    //   navbar.classList.add('navbar-inverse');
    // }
  }

  logout() {
    this.user = {};
    this.userIsLogin = false;
    this.authService.logout();
  }

  async ngOnInit(): Promise<void> {
    if (
      this.router.url.includes('/menus/') ||
      this.router.url.includes('/events/')
    ) {
    }
    // if (
    //   this.router.url.includes('/menus') ||
    //   this.router.url.includes('/events')
    // ) {
    this.shopping.deleteShoppingCart();
    // }
    this.viewCart = this.shopping.getShoppingCart();
    this.calculateTotalCart();

    this.viewCart$ = this.storageService.storageChange$.pipe(
      filter(({ storageArea }) => storageArea === 'sessionStorage'),
      filter(({ key }) => key === 'shoppingCart'),
      pluck('value')
    );

    this.viewCart$.subscribe((value) => {
      this.viewCart = JSON.parse(value);
      this.calculateTotalCart();
    });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (!this.isBack) {
          this.viewBack = true;
          this.history.push(val.urlAfterRedirects);
        }
        this.isBack = false;
      }

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      // if (
      //   this.router.url.includes('/menus') ||
      //   this.router.url.includes('/events')
      // ) {
      // }
      this.shopping.deleteShoppingCart();
    });
  }

  calculateTotalCart() {
    this.totalCart = this.viewCart.reduce(
      (acc: any, item: any) => acc + item.quantity * item.price,
      0
    );

    this.totalService = this.viewCart.reduce(
      (acc: any, item: any) => acc + item.quantity * 500,
      0
    );
  }

  removeProduct(product: any) {
    this.shopping.removeProduct(product);
  }

  reduceProduct(product: any) {
    this.shopping.reduceProduct(product);
  }
  addProduct(product: any) {
    this.shopping.addProduct(product);
  }

  async checkout() {
    this.spinnerService.showSpinner();
    if (this.router.url.includes('/events')) {
      (await this.checkoutService.checkoutAccess(this.viewCart)).subscribe(
        (data) => {
          if (data.result) {
            console.log(data.result);
            window.location.href = data.result.init_point;
            setTimeout(() => {
              this.spinnerService.hideSpinner();
            }, 2000);
          } else {
            this.spinnerService.hideSpinner();
            alert('checkoutAccess ' + data.message);
          }
        }
      );
    } else {
      (await this.checkoutService.checkoutBar(this.viewCart)).subscribe(
        (data) => {
          if (data.result) {
            window.location.href = data.result.init_point;
            setTimeout(() => {
              this.spinnerService.hideSpinner();
            }, 2000);
          } else {
            this.spinnerService.hideSpinner();
            alert('checkoutBar ' + data.message);
          }
        }
      );
    }
  }

  disableButton() {
    const disableButton = this.viewCart.length === 0 ? true : false;
    return disableButton;
  }

  goPage(page: string, sidenavMenu: any) {
    sidenavMenu.toggle();
    this.router.navigate([page]);
  }

  openTypePay(): void {
    const dialogRef = this.modal.open(SelectTypePayModalComponent, {
      data: {},
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async (result) => {});
  }

  navSide(type: any, accion: any, sidenav: any) {
    let element = document.querySelector('.checkoutButton ') as HTMLElement;
    let element2 = document.querySelector('.backButtom ') as HTMLElement;
    if (type === 'menu' && accion) {
      this.sideMenuIsOpen = true;
      if (element) element.style.display = 'none';
      if (element2) element2.style.display = 'none';
    }

    if (type === 'checkout' && accion) {
      this.sideCheckoutIsOpen = true;
      if (element) element.style.display = 'none';
      if (element2) element2.style.display = 'none';
    }

    if (type === 'menu' && !accion) {
      this.sideMenuIsOpen = false;
      if (element && !this.sideCheckoutIsOpen) element.style.display = 'flex';
      if (element2 && !this.sideCheckoutIsOpen) element2.style.display = 'flex';
    }

    if (type === 'checkout' && !accion) {
      this.sideCheckoutIsOpen = false;
      if (element && !this.sideMenuIsOpen) element.style.display = 'flex';
      if (element2 && !this.sideMenuIsOpen) element2.style.display = 'flex';
    }

    if (this.sideCheckoutIsOpen && this.sideMenuIsOpen && type === 'checkout') {
      sidenav.toggle();
    }
    if (this.sideCheckoutIsOpen && this.sideMenuIsOpen && type === 'menu') {
      sidenav.toggle();
    }
  }

  clickBack() {
    this.isBack = true;
    if (this.history.length > 0) {
      history.back();

      if (this.history.length == 1) {
        this.viewBack = false;
      }
      this.history.pop();
    }
  }

  closeSideNav(sidenav: any) {
    sidenav.toggle();
  }
}
