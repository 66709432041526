import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ShoppingService } from 'src/app/services/shopping.service';
import { filter, pluck } from 'rxjs/operators';
import { StorageService } from 'src/app/services/storage.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-toolbar-home-admin',
  templateUrl: './toolbar-admin-home.component.html',
  styleUrls: ['./toolbar-admin-home.component.scss'],
})
export class ToolbarAdminHomeComponent implements OnInit {
  userLogin: boolean = false;
  @Input() sidenavMenuAdmin: any;

  constructor(public authService: AuthService, public router: Router) {}

  async ngOnInit(): Promise<void> {
    const login = await this.authService
      .getLoginStatus()
      .subscribe((status: boolean) => {
        this.userLogin = status;
      });
  }

  logout() {
    this.authService.logout();
  }

  goPage(page: string) {
    this.router.navigate(['/admin' + page]);
  }

  goOtherPage(page: string, sidenavMenuAdmin: any) {
    sidenavMenuAdmin.toggle();
    this.router.navigate(['/' + page]);
  }
}
