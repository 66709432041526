import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ShoppingService } from 'src/app/services/shopping.service';
import { filter, pluck } from 'rxjs/operators';
import { StorageService } from 'src/app/services/storage.service';
@Component({
  selector: 'app-toolbar-home-workers',
  templateUrl: './toolbar-workers-home.component.html',
  styleUrls: ['./toolbar-workers-home.component.scss'],
})
export class ToolbarWorkersHomeComponent implements OnInit {
  userLogin: boolean = false;
  @Input() sidenavMenuWorkers: any;

  constructor(
    public authService: AuthService,
    private storageService: StorageService,
    private shopping: ShoppingService
  ) {}

  async ngOnInit(): Promise<void> {
    const login = await this.authService
      .getLoginStatus()
      .subscribe((status: boolean) => {
        this.userLogin = status;
      });
  }

  logout() {
    this.authService.logout();
  }
}
