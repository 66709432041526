export const environment = {
  firebase: {
    apiKey: 'AIzaSyCchSKDWdls4fyOKAyBwc6wYnvCq2qyoFs',
    authDomain: 'on-ticket.firebaseapp.com',
    projectId: 'on-ticket',
    storageBucket: 'on-ticket.appspot.com',
    messagingSenderId: '891935958295',
    appId: '1:891935958295:web:7b77b5848f8999ba4cc352',
    measurementId: 'G-53T14H4V9H',
  },
  debugFirebase: false,
  production: false,
  pwa: true,
  urlFront: 'https://dev-on-ticket.web.app/',
  urlBackend: 'https://dev-on-ticket-8671d8d27eeb.herokuapp.com/',
  urlBackendAdmin: 'https://dev-on-ticket-8671d8d27eeb.herokuapp.com/admins/',
  urlBackendWorker: 'https://dev-on-ticket-8671d8d27eeb.herokuapp.com/workers/',
};
