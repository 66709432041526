<div class="example-container">
  <app-toolbar-home
    [sidenavMenu]="sidenavMenu"
    [sidenavCheckOut]="sidenavCheckOut"
  >
  </app-toolbar-home>

  <mat-sidenav-container>
    <mat-sidenav
      #sidenavMenu
      (closed)="navSide('menu', false, sidenavCheckOut)"
      (opened)="navSide('menu', true, sidenavCheckOut)"
      position="start"
      class="footer d-flex"
      style="margin-top: 3.5rem !important"
    >
      <div class="container mt-3">
        <div class="col-12 mt-3 mb-3 container">
          <mat-icon style="float: right" (click)="closeSideNav(sidenavMenu)"
            >close
          </mat-icon>
        </div>

        <div
          class="row d-flex justify-content-center align-items-center col-12"
          style="padding: 16px"
          *ngIf="this.authService.getUser().uid"
        >
          <div class="col-3 no-padding d-flex justify-content-center">
            <div
              class="col-8"
              style="
                background: white;
                border-radius: 10px;
                border-radius: 10px;
                box-shadow: 0px 0px 10px 1px #5271ff;
              "
            >
              <img
                (click)="goPage('/profile', sidenavMenu)"
                class="card-img-top rounded"
                [src]="this.authService.getUser().photoURL"
              />
            </div>
          </div>
          <div class="col-9">
            <p class="m-0">Bienvenido</p>
            <p class="m-0">{{ this.authService.getUser().displayName }}</p>
          </div>
        </div>

        <mat-selection-list [multiple]="false">
          <div mat-subheader>Principal</div>
          <mat-list-option
            (click)="goPage('/welcome', sidenavMenu)"
            [ngClass]="router.url == '/welcome' ? 'menuSelect' : 'menuNoSelect'"
          >
            <mat-icon mat-list-icon>home</mat-icon>
            <div mat-line>Home</div>
          </mat-list-option>
          <mat-list-option
            (click)="goPage('/events', sidenavMenu)"
            [ngClass]="
              router.url.includes('/events') ? 'menuSelect' : 'menuNoSelect'
            "
          >
            <mat-icon mat-list-icon>celebration</mat-icon>
            <div mat-line>Eventos</div>
          </mat-list-option>

          <!-- <mat-list-option (click)="goPage('/menus',sidenavMenu) "
                        [ngClass]="router.url.includes('/menus')?'menuSelect':'menuNoSelect' ">
                        <mat-icon mat-list-icon>nightlife</mat-icon>
                        <div mat-line>Menus Cercanos</div>
                    </mat-list-option> -->

          <mat-list-option
            (click)="goPage('/business', sidenavMenu)"
            [ngClass]="
              router.url.includes('/business') ? 'menuSelect' : 'menuNoSelect'
            "
          >
            <mat-icon mat-list-icon>admin_panel_settings</mat-icon>
            <div mat-line>Publicar un evento</div>
          </mat-list-option>

          <mat-divider
            class="mt-2"
            *ngIf="this.authService.getUser().uid"
          ></mat-divider>

          <div *ngIf="this.authService.getUser().uid" mat-subheader>Cuenta</div>
          <mat-list-option
            (click)="goPage('/profile', sidenavMenu)"
            *ngIf="this.authService.getUser().uid"
            [ngClass]="
              router.url.includes('/profile') ? 'menuSelect' : 'menuNoSelect'
            "
          >
            <mat-icon mat-list-icon>account_circle</mat-icon>
            <div mat-line>Perfil</div>
          </mat-list-option>
          <!-- <mat-list-option>
                    <mat-icon mat-list-icon>payment</mat-icon>
                    <div mat-line>Cuenta Bancaria</div>
                </mat-list-option> -->
          <mat-list-option
            (click)="goPage('/tickets-bar', sidenavMenu)"
            *ngIf="this.authService.getUser().uid"
            [ngClass]="
              router.url.includes('/tickets-bar')
                ? 'menuSelect'
                : 'menuNoSelect'
            "
          >
            <mat-icon mat-list-icon>local_bar</mat-icon>
            <div mat-line>Tickets Barra</div>
          </mat-list-option>

          <mat-list-option
            (click)="goPage('/tickets-access', sidenavMenu)"
            *ngIf="this.authService.getUser().uid"
            [ngClass]="
              router.url.includes('/tickets-access')
                ? 'menuSelect'
                : 'menuNoSelect'
            "
          >
            <mat-icon mat-list-icon>confirmation_number</mat-icon>
            <div mat-line>Tickets de Accesos</div>
          </mat-list-option>

          <mat-list-option
            (click)="logout(); sidenavMenu.toggle()"
            *ngIf="this.authService.getUser().uid"
          >
            <mat-icon mat-list-icon>logout</mat-icon>
            <div mat-line>Salir</div>
          </mat-list-option>
          <mat-divider
            class="mt-2"
            *ngIf="this.authService.getUser().uid"
          ></mat-divider>
          <div
            mat-subheader
            *ngIf="
              this.authService.getUser().isAdmin ||
              this.authService.getUser().isWorker
            "
          >
            Otros Sistemas
          </div>

          <mat-list-option
            (click)="goPage('/admin', sidenavMenu)"
            *ngIf="this.authService.getUser().isAdmin"
          >
            <mat-icon mat-list-icon>admin_panel_settings</mat-icon>
            <div mat-line>Admin</div>
          </mat-list-option>

          <mat-list-option
            (click)="goPage('/workers', sidenavMenu)"
            *ngIf="this.authService.getUser().isWorker"
          >
            <mat-icon mat-list-icon>engineering</mat-icon>
            <div mat-line>Workers</div>
          </mat-list-option>
        </mat-selection-list>
      </div>

      <!-- <div class="footer">

                <mat-card class="button-pay">
                    <div class="row d-flex justify-content-center align-items-center ">
                        <button mat-raised-button class="col-10" (click)="logout(); sidenavMenu.toggle()" color="warn">
                            <mat-icon>logout</mat-icon> Salir
                        </button>
                    </div>
                </mat-card>



            </div> -->

      <!-- <div class="footer">
                <div class=" col-12 row d-flex justify-content-center align-items-center ">
                    <button class="col-10 " (click)="logout(); sidenavMenu.toggle()" color="warn" mat-stroked-button>
                        <mat-icon>logout</mat-icon>
                        <span>Salir</span>
                    </button>
                </div>




            </div> -->
    </mat-sidenav>

    <mat-sidenav
      #sidenavCheckOut
      (closed)="navSide('checkout', false, sidenavMenu)"
      (opened)="navSide('checkout', true, sidenavMenu)"
      position="end"
      class="footer d-flex"
      style="margin-top: 3.5rem !important; background-color: white"
    >
      <div
        *ngIf="!disableButton()"
        class="no-margin no-padding container mt-3 row"
      >
        <div class="col-12 mt-3 mb-3">
          <mat-icon (click)="closeSideNav(sidenavCheckOut)">close </mat-icon>
        </div>
        <div class="col-12">
          <mat-card
            ngClass="mat-card-checkout-bar"
            *ngFor="let product of viewCart"
            class="mb-2"
          >
            <div class="row">
              <div class="col-8">
                <h4 style="margin-bottom: 0px" class="text-truncate">
                  {{ product.name }}
                </h4>
                <!-- <mat-card-title>{{product.name}}</mat-card-title> -->
                <mat-card-subtitle
                  >Cantidad : {{ product.quantity }}</mat-card-subtitle
                >
                <mat-card-subtitle class="d-flex align-items-center"
                  >Total :
                  <mat-icon class="col-2">attach_money</mat-icon>

                  {{ product.quantity * product.price | number }}
                </mat-card-subtitle>
              </div>
              <div
                style="touch-action: manipulation"
                class="col-4 d-flex justify-content-center align-items-center no-padding"
              >
                <div
                  style="touch-action: manipulation"
                  (click)="reduceProduct(product)"
                  *ngIf="product.quantity !== 1"
                  class="col-6 d-flex justify-content-center"
                >
                  <button mat-mini-fab color="accent">
                    <mat-icon>remove</mat-icon>
                  </button>
                </div>
                <div
                  style="touch-action: manipulation"
                  (click)="removeProduct(product)"
                  *ngIf="product.quantity === 1"
                  class="col-6 d-flex justify-content-center"
                >
                  <button mat-mini-fab color="accent">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>

                <div
                  *ngIf="product.quantity < quantityAccept"
                  (click)="addProduct(product)"
                  class="col-6 d-flex justify-content-center"
                >
                  <button mat-mini-fab color="accent">
                    <mat-icon>add</mat-icon>
                  </button>
                </div>

                <div
                  *ngIf="product.quantity === quantityAccept"
                  style="touch-action: manipulation"
                  class="col-6 d-flex justify-content-center no-padding no-margin"
                >
                  <button mat-mini-fab style="background-color: #a1a1a1">
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </mat-card>
          <br /><br /><br /><br />
          <br /><br /><br /><br />
        </div>
      </div>

      <div
        *ngIf="disableButton()"
        class="no-margin no-padding container mt-3 row"
      >
        <div class="col-12 mt-3 mb-3">
          <mat-icon (click)="closeSideNav(sidenavCheckOut)">close </mat-icon>
        </div>
      </div>

      <div
        *ngIf="disableButton()"
        class="container mt-3 d-flex justify-content-center align-items-center text-center"
        style="color: white; margin-top: 40vh !important"
      >
        <h1>No hay productos selecionados</h1>
      </div>

      <div class="footer">
        <mat-card class="button-pay">
          <div class="row d-flex justify-content-center align-items-center">
            <!--
                        <button class="col-10 mb-2 " color="warn" mat-stroked-button>¿ Tienes un codigo promocional ?

                        </button> -->

            <!-- <div class=" col-10 row  no-padding">

                            <div class="col-6 d-flex  align-items-center  no-padding">
                                <mat-icon>attach_money</mat-icon>
                                <span> &nbsp; Total : {{totalCart | number}}</span>
                            </div>

                            <div class="col-6 d-flex  align-items-center  no-padding" style="    position: relative;">
                                <mat-icon>room_service</mat-icon>

                                <span style="position: absolute;
                                right: 0;"> &nbsp; Servicio : {{totalService | number}}</span>
                            </div>


                        </div> -->

            <div class="col-10 mt-2 row no-padding">
              <button
                mat-raised-button
                class="col-12"
                color="warn"
                [disabled]="disableButton()"
                (click)="openTypePay()"
              >
                <!-- (click)="checkout()" -->
                <mat-icon>shopping_cart</mat-icon> Ir a pagar
                <!-- : {{totalCart+totalService|number}} -->
              </button>
            </div>

            <button
              class="col-10 mt-2"
              [disabled]="disableButton()"
              (click)="shopping.deleteShoppingCart()"
              color="warn"
              mat-stroked-button
            >
              Borrar Carrito
            </button>
          </div>
        </mat-card>
      </div>
    </mat-sidenav>
    <!-- style="margin-top: 4.5rem !important" -->
    <mat-sidenav-content>
      <router-outlet></router-outlet>

      <app-home-footer></app-home-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<div *ngIf="viewBack" class="backButtom">
  <button
    (click)="clickBack()"
    color="primary"
    matBadgePosition="after"
    matBadgeColor="warn"
    mat-mini-fab
    aria-label=""
  >
    <mat-icon>arrow_back</mat-icon>
  </button>
</div>
