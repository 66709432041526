import { Component, HostListener } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { StorageService } from './services/storage.service';
import { filter, pluck } from 'rxjs/operators';
import { SpinnerService } from './services/spinner.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'on-ticket';

  loadingSpinner = 'loadingSpinner';
  showLoading$!: Observable<string>;

  constructor(
    private spinner: NgxSpinnerService,
    private storageService: StorageService,
    private spinnerService: SpinnerService,
    private swUpdate: SwUpdate
  ) {
    this.spinnerService.showSpinner();
  }

  ngOnInit() {
    this.showLoading$ = this.storageService.storageChange$.pipe(
      filter(({ storageArea }) => storageArea === 'sessionStorage'),
      filter(({ key }) => key === 'loadingSpinner'),
      pluck('value')
    );

    this.showLoading$.subscribe((value) => {
      if (value === 'show') {
        this.showSpinner('loadingSpinner');
      } else {
        this.hideSpinner('loadingSpinner');
      }
    });
  }

  hideSpinner(name: string) {
    this.spinner.hide(name);
  }
  showSpinner(name: string) {
    this.spinner.show(name);
  }
}
